/* GLOBAL */
html, 
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

/* FONTS */
@font-face {
  font-family: poppins_regular;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: poppins_medium;
  src: url(../assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: poppins_bold;
  src: url(../assets/fonts/Poppins-Bold.ttf);
}